<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="gigs"
            :items-per-page="15"
            class="elevation-1"
            sort-by="starts_at"
            sort-desc
        >
            <template v-slot:item.title="{ item }">
                <router-link :to="{name: 'venue.gigs.show', params: {id: item.id}}">{{ item.title }}</router-link>
            </template>
            <template v-slot:item.starts_at="{ item }">
                {{$dayjs(item.starts_at).format('MMM DD, YYYY, h:mm A')}}
            </template>
            <template v-slot:item.ends_at="{ item }">
                {{$dayjs(item.ends_at).format('MMM DD, YYYY, h:mm A')}}
            </template>
            <template v-slot:item.is_confirmed_by_venue="{ item }">
                <v-icon v-if="item['is_confirmed_by_venue']">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template v-slot:item.is_confirmed_by_artist="{ item }">
                <v-icon v-if="item['is_confirmed_by_artist']">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template v-slot:item.actions="{item}">
                <div v-if="!item['is_confirmed_by_venue'] && !item['rejected_at']">
                    <v-btn color="green" @click="confirm(item)"> confirm</v-btn>
                    <v-btn color="red" @click="reject(item)"> reject</v-btn>
                </div>
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import Gig from '@/models/Gig';
import Venue from "@/models/Venue";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-index",
    components: {DashboardLayout,},
    data: function () {
        return {
            loading: false,
            gigs: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Artist', value: 'artist.name'},
                {text: 'Confirmed by Venue', value: 'is_confirmed_by_venue'},
                {text: 'Confirmed by Artist', value: 'is_confirmed_by_artist'},
                {text: 'Starts at', value: 'starts_at'},
                {text: 'Ends at', value: 'ends_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        async confirm(gig) {
            gig.is_confirmed_by_venue = (await gig.confirm()).is_confirmed_by_venue;
        },
        async reject(gig) {
            gig.rejected_at = (await gig.reject()).rejected_at;
        },
    },
    async mounted() {
        this.loading = true
        let venue = await Venue.where('user_id', this.$auth.user().id).first()
        if (venue) {
            this.gigs = await Gig.where('venue_id', venue.id).include(['venue', 'artist']).get()
        }
        this.loading = false
    },
}
</script>

<style scoped>

</style>
